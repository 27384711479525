import * as React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import MainWrapper from "../components/siteWrapper";
import Quote from "../components/quote";

// images
import Books from "../images/booksBackground.jpg";

const HeroDesktop = styled.div`
  background: url(${Books}) center;
  height: 400px;
  width: 100%;
`;

const IndentedP = styled.p`
margin-left: 1.5rem;
`

const AskToJoinPage = () => (
  <Layout>
    <HeroDesktop />

    <MainWrapper>
      {/* introduction */}
      <Quote>
        <p>
          “There are no strangers in Freemasonry, only friends you’ve yet to
          meet.”
        </p>
        <p>W. Bro Dave Thomas</p>
      </Quote>

      {/* main start */}

      <div>
        <h1>Ask to join the Freemasons</h1>
        <h2>How to become a Freemason at Perfection</h2>
        <p>
          You can only become a Freemason through your own volition. We do not
          solicit people!
        </p>
        <p>
          When you choose to make your application, the decision of your
          acceptance rests with the Lodge members. If you put some thought into
          becoming a Freemason, you should approach a friend whom you know to be
          a Mason who will explain the process. If you do not know someone or
          choose to not involve them, please email our <a
            href="mailto:WMcRobbie@hotmail.com"
            title="join the freemasons at perfection lodge no 75"
        >
          membership committee
        </a>.
        </p>
        <p>
          Perfection Lodge No. 75 is located in the Royal City of New
          Westminster, British Columbia. However, we are willing to help any man
          anywhere in the world become a Freemason. Again, we invite you to <a
            href="mailto:WMcRobbie@hotmail.com"
            title="join the freemasons at perfection lodge no 75"
        >
          contact our membership committee
        </a> with any questions if you are interested in learning more
          about or joining the fraternity.
        </p>
        <p>
          If you are certain about your decision to join Freemasonry, we are
          happy to assist by providing the knowledge, resources and connections
          you will need in order to do so.
        </p>
      </div>

      <div>
        <h2>Eligibility.</h2>
        <p>
          To be eligible for membership in the jurisdiction of the Grand Lodge
          of Ancient Free and Accepted Masons of British Columbia and Yukon an
          applicant must satisfy the following qualifications:
        </p>
        <p>1&#41; The ability to read and write in English.</p>
        <p>
          2&#41; Have resided in British Columbia or the Yukon for the previous
          six months.
        </p>
        <p>
          3&#41; Have resided within the jurisdiction of the constituent lodge
          for the previous six months.
        </p>
        <p>
          4&#41; “shall be a man, of the full age of 21 years, free born and
          capable of supporting himself and those dependent upon him.”
        </p>
        <p>
          5&#41; Be, in clear conscience, able to answer the following questions
          in the affirmative:
        </p>
        <IndentedP>
          a&#41; Do you seriously and upon your honour declare that uninfluenced
          by mercenary or other unworthy motives, and unbiased by the improper
          solicitations of friends, you freely and voluntarily offer yourself a
          candidate for the mysteries of Freemasonry?
        </IndentedP>
        <IndentedP>
          b&#41; Do you seriously and upon your honour declare that you are
          prompted to solicit the privileges of Freemasonry by a favorable
          opinion conceived of the Institution and a desire for knowledge?
        </IndentedP>
        <IndentedP>
          c&#41; Do you believe in the existence of a Supreme Being?
        </IndentedP>
      </div>

      <h2>Interested in joining the oldest Fraternity in the world?</h2>
      <p>
        Email{" "}
        <a
          href="mailto:WMcRobbie@hotmail.com"
          title="join the freemasons at perfection lodge no 75"
        >
          WMcRobbie@hotmail.com
        </a>{" "}
        to reach the membership committee.
      </p>
    </MainWrapper>
  </Layout>
);

export default AskToJoinPage;

export function Head( {location} ) {

  const title = "Looking to join the Freemasons?";
  const description = "Reach out through the email in this page to ask to join the Fraternity.";
  const siteURL = "https://newwestfreemasons.ca"

  return (
      <>
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Perfection Lodge No. 75, New Westminster" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="ca_EN" />
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content={`${siteURL}${location.pathname}/`}
        />
        <meta property="og:image" content="https://newwestfreemasons.ca/logo.png" />
        <link rel="canonical" href={`${siteURL}${location.pathname}/`} />
      </>
  )
}